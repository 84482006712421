//公共的方法
//主表
import { updateInstoreStatus, listBill } from '@/api/purchase/index'
import { allPartnerList, allCustomerList } from '@/api/partner/partner'
import { allStoreList } from '@/api/system/store'
import { allEmployeeList } from '@/api/system/employee'
//明细表
import { getAllRegion } from '@/api/goods/region'
import { getStoreAddress } from '@/api/goods/store'
import { treeselect } from '@/api/system/dept'
import { listGoods } from '@/api/goods/goods'
import {
  addBill,
  getDetailBill,
  updateBill,
  getSourceBill,
  getGoodsFinalQty
} from '@/api/purchase/index'

//单据主表

//根据单据类型更改api
export function changeBillType(billType) {
  // url  更新单据状态
  // urlList  单据列表数据
  // routerName  路由名称
  // diySelectType  区分自定义搜索区域
  // billNoLabel  单据编号字段名称
  // getCusSupp   要获取(客户/供应商)数据
  // showStore   显示的仓库(出库入库仓库)
  // showEmployee  显示采购员/业务员
  if (billType === '110101') {
    //采购订单
    this.url = '/api/system/purchase/bill/updateBillStatus'
    this.urlList = '/api/system/purchase/bill/list'
    this.routerName = 'PurchaseOrderDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '供'
    this.showStore = 'storeId'
    this.showEmployee = 'pur'
  } else if (billType === '110102') {
    //采购入库单
    this.url = '/api/system/purchase/store/updateBillStatus'
    this.urlList = '/api/system/purchase/store/list'
    this.routerName = 'PurchaseInstoreDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '供'
    this.showStore = 'inStoreId'
    this.showEmployee = 'pur'
  } else if (billType === '110103') {
    //采购退货单
    this.url = '/api/system/purchase/return/updateBillStatus'
    this.urlList = '/api/system/purchase/return/list'
    this.routerName = 'PurchaseBackDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '供'
    this.showStore = 'outStoreId'
    this.showEmployee = 'pur'
  } else if (billType === '120101') {
    //销售订单
    this.url = '/api/system/sale/bill/updateBillStatus'
    this.urlList = '/api/system/sale/bill/list'
    this.routerName = 'SaleOrderDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '客'
    this.showStore = 'storeId'
    this.showEmployee = 'sale'
  } else if (billType === '140313') {
    //门店销售订单
    this.url = '/api/system/shop/bill/shopSale/updateBillStatus'
    this.urlList = '/api/system/shop/bill/shopSale/list'
    this.routerName = 'shopClientSaleOrderDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '客'
    this.showStore = 'storeId'
    this.showEmployee = 'sale'
  } else if (billType === '120102') {
    //销售出库单
    this.url = '/api/system/sale/outbound/updateBillStatus'
    this.urlList = '/api/system/sale/outbound/list'
    this.routerName = 'SaleOutDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '客'
    this.showStore = 'outStoreId'
    this.showEmployee = 'sale'
  } else if (billType === '120103') {
    //销售退货单
    this.url = '/api/system/sale/return/updateBillStatus'
    this.urlList = '/api/system/sale/return/list'
    this.routerName = 'SaleBackDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '客'
    this.showStore = 'inStoreId'
    this.showEmployee = 'pur'
  } else if (billType === '107') {
    //仓库盘点单
    this.url = '/api/system/store/check/updateBillStatus'
    this.urlList = '/api/system/store/check/list'
    this.diySelectType = true
    this.routerName = 'storeCheckDetail'
    this.billNoLabel = '盘点编号'
  } else if (billType === '108') {
    //库存调整单
    this.url = '/api/system/store/bill/adjust/updateBillStatus'
    this.urlList = '/api/system/store/bill/adjust/list'
    this.billNoLabel = '库存调整编号'
    this.diySelectType = true
    this.routerName = 'storeAdjustDetail'
  } else if (billType === '130103') {
    //仓库报损单
    this.url = '/api/system/store/bill/loss/updateBillStatus'
    this.urlList = '/api/system/store/bill/loss/list'
    this.billNoLabel = '报损编号'
    this.diySelectType = true
    this.routerName = 'storeWasteDetail'
  } else if (billType === '130104') {
    //仓库调拨单
    this.url = '/api/system/store/bill/allot/updateBillStatus'
    this.urlList = '/api/system/store/bill/allot/list'
    this.billNoLabel = '调拨编号'
    this.diySelectType = true
    this.routerName = 'storeMoveDetail'
  } else if (billType === '130105') {
    //仓库调出单
    this.billNoLabel = '调出编号'
    this.diySelectType = true
    this.routerName = 'storeMoveOutDetail'
    this.url = '/api/system/store/bill/out/updateBillStatus'
    this.urlList = '/api/system/store/bill/out/list'
  } else if (billType === '130106') {
    //仓库调入单
    this.billNoLabel = '调入编号'
    this.diySelectType = true
    this.routerName = 'storeMoveInDetail'
    this.url = '/api/system/store/bill/in/updateBillStatus'
    this.urlList = '/api/system/store/bill/in/list'
  } else if (billType === '130107') {
    //仓库盘盈单
    this.billNoLabel = '盘盈编号'
    this.diySelectType = true
    this.routerName = 'storeCheckFullDetail'
    this.url = '/api/system/store/bill/inventory/surplus/updateBillStatus'
    this.urlList = '/api/system/store/bill/inventory/surplus/list'
  } else if (billType === '130108') {
    //仓库盘亏单
    this.billNoLabel = '盘亏编号'
    this.diySelectType = true
    this.routerName = 'storeCheckLoseDetail'
    this.url = '/api/system/store/bill/inventory/loss/updateBillStatus'
    this.urlList = '/api/system/store/bill/inventory/loss/list'
  } else if (billType === '000') {
    // 调拨差异单
    this.billNoLabel = '调拨差异编号'
    this.diySelectType = true
    this.routerName = 'storeMoveDiffDetail'
  } else if (billType === '140303') {
    this.url = '/api/system/shop/bill/purchase/updateBillStatus'
    this.urlList = '/api/system/shop/bill/purchase/list'
    this.routerName = 'StockInDetail'
    this.billNoLabel = '单据编号'
    this.getCusSupp = '供'
    this.showStore = 'inStoreId'
    this.showEmployee = 'pur'
  }
}

/** 查询单据列表 */
export function getList() {
  const url = this.urlList
  if (!url) return
  this.loading = true
  listBill(Object.assign({}, this.purSelectList, this.queryParams), url).then(
    response => {
      this.purList = response.rows
      this.total = response.total
      this.loading = false
    }
  )
}

//更新单据状态(审核,反审核,删除,关闭)
export function handleBillStatus(command, selectData, url, BillType, type) {
  let billData = [] //过滤的数据
  let name = '' //要执行的状态名字
  let tag = '' //提示的状态
  let billStatus = '' //要执行的状态数字
  if (command === '反审核') {
    // billData = selectData.filter(item => (item.billStatus === '2'))
    name = '反审核'
    tag = '已审核'
    billStatus = '0'
  } else {
    // billData = selectData.filter(item => (item.billStatus === '0'))
    if (command === '审核') {
      name = '审核'
      tag = '未审核'
      billStatus = '2'
    } else if (command === '删除') {
      name = '删除'
      tag = '未审核'
      billStatus = '3'
    } else if (command === '关闭') {
      name = '关闭'
      tag = '未审核'
      billStatus = '4'
    }
  }
  // console.log('进来了')
  // if(!(billData.length === 0)){
  const billId = selectData.map(i => i.billId)
  const billNo = selectData.map(i => i.billNo)
  const $this = this
  this.$modal
    .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
    .then(function () {
      return updateInstoreStatus([...billId], billStatus, url, BillType).then(
        () => {
          $this.$nextTick(() => {
            $this.$refs.BillTable.clearSelection()
          })
          $this.$modal.msgSuccess(`${name}成功`)
          setTimeout(() => {
            $this.getList($this.urlList)
          }, 500)
        }
      )
    })
  //  }else{
  // this.$message.error(`选中的数据中不含有${tag}的单据,不能${name}`)
  //  }
}

//获取搜索下拉框数据
export function getBasicData(sign, type) {
  //sign区分主表或者明细表    type 区分供应商获取客户的数据
  if (type === '供') {
    //获取供应商数据
    allPartnerList().then(response => {
      this.partnerData = response.data
    })
  } else {
    //获取客户数据
    allCustomerList().then(response => {
      this.customerData = response.data
    })
  }
  //获取仓库数据
  allStoreList().then(response => {
    this.storeData = response.data
  })
  //获取员工数据
  allEmployeeList().then(response => {
    this.employeeData = response.data
  })
  if (sign === 'detail') {
    //获取部门数据
    treeselect().then(response => {
      this.deptData = response.data
    })
  }
}

//点击全选所有数据
export async function selectAll() {
  this.isCheckAll = !this.isCheckAll
  if (this.isCheckAll) {
    if (this.purAllList.length === 0) {
      //获取全部采购入库单数据
      const res = await listBill(
        { ...this.purSelectList, pageNum: 1, pageSize: this.total },
        this.urlList
      )
      this.purAllList = res.rows
    }
    this.purAllList.map(row => {
      for (let i = 0; i < this.selectData.length; i++) {
        if (row.billId === this.selectData[i].billId) return
      }
      this.$nextTick(() => {
        this.$refs.BillTable.toggleRowSelection(row, this.isCheckAll)
      })
    })
  } else {
    this.$nextTick(() => {
      this.$refs.BillTable.clearSelection()
      this.purAllList = []
    })
  }
  this.getList(this.urlList)
}

//表格选择发生变化时触发
export function handleSelectionChange(selection) {
  this.selectData = selection
  this.ids = selection.map(item => item.billId)
  this.multiple = !selection.length
}

//是否显示高级搜索
export function showHighSearch() {
  this.icon =
    this.icon === 'el-icon-caret-bottom'
      ? 'el-icon-caret-top'
      : 'el-icon-caret-bottom'
  this.showHigh = !this.showHigh
  this.tableHeight = this.tableHeight === '550' ? '450' : '550'
}

//刷新按钮
export function refresh(name) {
  if (name === 'refresh') {
    this.$nextTick(() => {
      this.$refs.BillTable.clearSelection()
      this.getList(this.urlList)
    })
  } else {
    this.$nextTick(() => {
      this.purSelectList = {
        //页码
        pageNum: 1,
        //页容量
        pageSize: 15,
        //单据开始日期时间
        beginPayTime: '',
        //单据结束日期时间
        endPayTime: '',
        //单据编号
        billNo: '',
        //供应商
        partnerId: '',
        //仓库
        storeId: '',
        //采购员
        purUserId: '',
        //单据状态
        billStatus: '',
        // 制单人
        createEmployeeId: undefined,
        //时间过滤
        filterTime: ''
      }
      //单据查询时间
      this.datetime = undefined
      this.supplierVal = undefined

      this.$refs.BillTable.clearSelection()
      this.getList(this.urlList)
    })
  }
}

//新增单据方法
export function handleAdd(name) {
  console.log('name：----', name)
  this.$router.push({
    name,
    query: {
      type: 'Add'
    }
  })
}

//点击单据编号获取id 跳转详情页
export function handleUpdate(row, name) {
  const billId = row.billId
  this.$router.push({
    name,
    query: {
      billId: billId,
      type: 'Update'
    }
  })
}

//清空搜索区域
export function Masterreset() {
  this.purSelectList = {
    //页码
    pageNum: 1,
    //页容量
    pageSize: 15,
    //单据开始日期时间
    beginPayTime: '',
    //单据结束日期时间
    endPayTime: '',
    //单据编号
    billNo: '',
    //供应商
    partnerId: '',
    //仓库
    storeId: '',
    //采购员
    purUserId: '',
    //单据状态
    billStatus: '',
    //时间过滤
    filterTime: ''
  }
  //单据查询时间
  this.datetime = undefined
}

//单据明细表

//根据单据类型更改api
export function changeBillDetailType(billType) {
  console.log('=-=-=--= ', billType)
  if (billType === '110101') {
    // 采购订单
    this.urlDetail = '/api/system/purchase/bill/getDetail' //获取详情url
    this.urlUpdate = '/api/system/purchase/bill/update' //修改url
    this.urlAdd = '/api/system/purchase/bill/save' //新增url
    this.urlBillStatus = '/api/system/purchase/bill/updateBillStatus' //更新单据状态url
    this.BillSaleType = true // 标识采购/销售
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
    this.storePostType = 'storeId' //区分仓库字段  (入库/出库/订单)
    this.getCusSupp = '供' //区别要获取(客户/供应商)数据
  } else if (billType === '110102') {
    //采购入库单
    this.urlDetail = '/api/system/purchase/store/getDetail' //获取详情url
    this.urlUpdate = '/api/system/purchase/store/update' //修改url
    this.urlAdd = '/api/system/purchase/store/save' //新增url
    this.urlBillStatus = '/api/system/purchase/store/updateBillStatus' //更新单据状态url
    this.urlSourceBill = '/api/system/purchase/bill/getSourceBillOrder' //引入单据url
    this.BillSaleType = true
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
    this.storePostType = 'inStoreId' //区分仓库字段  (入库/出库/订单)
    this.getCusSupp = '供' //区别要获取(客户/供应商)数据
  } else if (billType === '110103') {
    //采购退货单
    this.urlDetail = '/api/system/purchase/return/getDetail'
    this.urlUpdate = '/api/system/purchase/return/update'
    this.urlAdd = '/api/system/purchase/return/save'
    this.urlBillStatus = '/api/system/purchase/return/updateBillStatus'
    this.urlSourceBill = '/api/system/purchase/return/getSourceBillOrder'
    this.BillSaleType = true
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
    this.storePostType = 'outStoreId'
    this.getCusSupp = '供'
  } else if (billType === '120101') {
    //销售订单
    this.urlDetail = '/api/system/sale/bill/getDetail'
    this.urlUpdate = '/api/system/sale/bill/update'
    this.urlAdd = '/api/system/sale/bill/save'
    this.urlBillStatus = '/api/system/sale/bill/updateBillStatus'
    this.BillSaleType = false
    this.unitPriceType = 'salePrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
    this.storePostType = 'storeId'
    this.getCusSupp = '客'
  } else if (billType === '140313') {
    //门店销售订单
    this.urlDetail = '/api/system/shop/bill/shopSale/getDetail'
    this.urlUpdate = '/api/system/shop/bill/shopSale/update'
    this.urlAdd = '/api/system/shop/bill/shopSale/save'
    this.urlBillStatus = '/api/system/shop/bill/shopSale/updateBillStatus'
    this.BillSaleType = false
    this.unitPriceType = 'salePrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
    this.storePostType = 'storeId'
    this.getCusSupp = '客'
  } else if (billType === '120102') {
    //销售出库单    120102
    this.urlDetail = '/api/system/sale/outbound/getDetail'
    this.urlUpdate = '/api/system/sale/outbound/update'
    this.urlAdd = '/api/system/sale/outbound/save'
    this.urlBillStatus = '/api/system/sale/outbound/updateBillStatus'
    this.urlSourceBill = '/api/system/sale/bill/getSourceBillOrder'
    this.BillSaleType = false
    this.unitPriceType = 'salePrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
    this.storePostType = 'outStoreId'
    this.getCusSupp = '客'
  } else if (billType === '120103') {
    //销售退货单
    this.urlDetail = '/api/system/sale/return/getDetail'
    this.urlUpdate = '/api/system/sale/return/update'
    this.urlAdd = '/api/system/sale/return/save'
    this.urlBillStatus = '/api/system/sale/return/updateBillStatus'
    this.urlSourceBill = '/api/system/sale/return/listSaleOutStoreSourceOrder'
    this.BillSaleType = false
    this.unitPriceType = 'salePrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
    this.storePostType = 'inStoreId'
    this.getCusSupp = '客'
  } else if (billType === '130101') {
    //仓库盘点单
    this.showStoreSale = true //显示仓库的字段
    this.urlAdd = '/api/system/store/check/save'
    this.urlDetail = '/api/system/store/check/getDetail'
    this.urlUpdate = '/api/system/store/check/update'
    this.urlBillStatus = '/api/system/store/check/updateBillStatus'
    this.urlSourceBill = '/api/system/purchase/bill/getSourceBillOrder'
    this.qtyLabel = '盘点数' //数量的字段
    this.moneyLabel = '金额' //金额的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '130102') {
    //库存调整单
    this.urlAdd = '/api/system/store/bill/adjust/save'
    this.urlDetail = '/api/system/store/bill/adjust/getDetail'
    this.urlUpdate = '/api/system/store/bill/adjust/update'
    this.urlBillStatus = '/api/system/store/bill/adjust/updateBillStatus'
    this.qtyLabel = '调整数量' //数量的字段
    this.moneyLabel = '金额' //金额的字段
    this.showStoreSale = true //显示仓库的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '130103') {
    //仓库报损单
    this.urlAdd = '/api/system/store/bill/loss/save'
    this.urlDetail = '/api/system/store/bill/loss/getDetail'
    this.urlUpdate = '/api/system/store/bill/loss/update'
    this.urlBillStatus = '/api/system/store/bill/loss/updateBillStatus'
    this.qtyLabel = '报损数量' //数量的字段
    this.moneyLabel = '金额' //金额的字段
    this.showStoreSale = true //显示仓库的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '130104') {
    //仓库调拨单
    this.urlAdd = '/api/system/store/bill/allot/save'
    this.urlDetail = '/api/system/store/bill/allot/getDetail'
    this.urlUpdate = '/api/system/store/bill/allot/update'
    this.urlBillStatus = '/api/system/store/bill/allot/updateBillStatus'
    this.moneyLabel = '金额' //金额的字段
    this.qtyLabel = '调拨数量' //数量的字段
    this.showStoreSale = true //显示仓库的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '130105') {
    //仓库调出单
    this.showStoreSale = true //显示仓库的字段
    this.urlAdd = '/api/system/store/bill/out/save'
    this.urlDetail = '/api/system/store/bill/out/getDetail'
    this.urlUpdate = '/api/system/store/bill/out/update'
    this.urlBillStatus = '/api/system/store/bill/out/updateBillStatus'
    this.qtyLabel = '调出数量' //数量的字段
    this.moneyLabel = '金额' //金额的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '130106') {
    //仓库调入单
    this.showStoreSale = true //显示仓库的字段
    this.urlAdd = '/api/system/store/bill/in/save'
    this.urlDetail = '/api/system/store/bill/in/getDetail'
    this.urlUpdate = '/api/system/store/bill/in/update'
    this.urlBillStatus = '/api/system/store/bill/in/updateBillStatus'
    this.qtyLabel = '调入数量' //数量的字段
    this.moneyLabel = '金额' //金额的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '130107') {
    //仓库盘盈单
    this.showStoreSale = true //显示仓库的字段
    this.urlAdd = '/api/system/store/bill/inventory/surplus/save'
    this.urlDetail = '/api/system/store/bill/inventory/surplus/getDetail'
    this.urlUpdate = '/api/system/store/bill/inventory/surplus/update'
    this.urlBillStatus =
      '/api/system/store/bill/inventory/surplus/updateBillStatus'
    this.qtyLabel = '盘盈数量' //数量的字段
    this.moneyLabel = '盘盈金额' //金额的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '130108') {
    //仓库盘亏单
    this.showStoreSale = true //显示仓库的字段
    this.urlAdd = '/api/system/store/bill/inventory/loss/save'
    this.urlDetail = '/api/system/store/bill/inventory/loss/getDetail'
    this.urlUpdate = '/api/system/store/bill/inventory/loss/update'
    this.urlBillStatus =
      '/api/system/store/bill/inventory/loss/updateBillStatus'
    this.qtyLabel = '盘亏数量' //数量的字段
    this.moneyLabel = '盘亏金额' //金额的字段
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  } else if (billType === '1000') {
    // 调拨差异单
    this.qtyLabel = '已发货数量' //数量的字段
    this.showStoreSale = true //显示仓库的字段
  } else if (billType === '140303') {
    this.urlDetail = '/api/system/shop/bill/purchase/getDetail'
    this.urlUpdate = '/api/system/shop/bill/purchase/update'
    this.urlAdd = '/api/system/shop/bill/purchase/save'
    this.urlBillStatus = '/api/system/shop/bill/purchase/updateBillStatus'
    this.urlSourceBill = '/api/system/purchase/bill/getSourceBillOrder'
    this.BillSaleType = true //区别是否为仓库单据类型或者采购销售类型
    this.storePostType = 'inStoreId' //区分仓库字段  (入库/出库/订单)
    this.getCusSupp = '供' //区别要获取(客户/供应商)数据
    this.unitPriceType = 'purPrice' //标识单价取进价/售价  purPrice=进价  salePrice=售价
  }

  this.getBasicData('detail', this.getCusSupp)
}

//统一调用 showFormatting handleEdit 方法  失去焦点自动计算并保留3位小数
export function allFun(row, name, e, type, value, index) {
  if (row === '') {
    this.watchScroll()
  }
  this.showFormatting(
    3,
    type,
    e,
    this.showForm.details,
    this.form.details,
    value,
    index
  )
  this.handleEdit(row, name, index)
}

//编码获取焦点事件
export function onGoodsNoFocus() {
  if (this.TypeBill === '107' && !this.form.storeId) {
    //仓库盘点时需要校验仓库是否选中
    this.$message({
      message: '请先选择仓库',
      type: 'warning'
    })
    return
  }
}

//获取附件上传的信息数据
export function getFileItemsData(data) {
  this.form.fileItems.push(data)
  console.log('csp - 上传成功 === ', data)
  // this.fileList.push({
  //     name: data.fileName,
  //     url: data.accessUrl
  // })
}
// 删除附件
export function delFileItems(data) {
  console.log('csp - 删除附件', data)
  this.form.fileItems.forEach((item, index) => {
    if (item.accessUrl == data) {
      this.form.fileItems.splice(index, 1)
    }
  })
  console.log('csp - 删除成功', this.form.fileItems)
}

//表格滚动条的位置
//监听数据变化失去焦点时  滚动条的位置
export function watchScroll() {
  if (this.scrollLeft) this.savescroll()
}

//监听滚动事件
export function scroll() {
  this.$refs.dragTable.bodyWrapper.addEventListener('scroll', this.scrollEvent)
}

//移除监听滚动事件
export function destroyScroll() {
  this.$refs.dragTable.bodyWrapper.removeEventListener(
    'scroll',
    this.scrollEvent
  )
}

// 获取滚动的宽度
export function scrollEvent(e) {
  this.scrollLeft = e.target.scrollLeft
}

// 数据刷新后设置滚动条当前滚动的位置
export function savescroll() {
  this.$nextTick(() => {
    setTimeout(() => {
      let scroll = this.$el.querySelector('.el-table__body-wrapper')
      scroll.scrollLeft = this.scrollLeft
    }, 10)
  })
}

//获取全部行政地区事件
export function getRegion() {
  this.loading = true
  getAllRegion().then(response => {
    this.provinceList = response.data
    this.loading = false
  })
}

//设置收货地址
export function setAddress() {
  this.openAddress = true
  this.getRegion()
}

// 选中的仓库获取收货地址
export function setAddressInfo(id) {
  let store
  if (this.storePostType === 'storeId') {
    store = 'storeId'
  } else if (this.storePostType === 'inStoreId') {
    store = 'inStoreId'
  } else if (this.storePostType === 'outStoreId') {
    store = 'outStoreId'
  }
  this.$refs.form.validateField(store)
  if (this.form[store]) {
    getStoreAddress(id).then(response => {
      this.form.storeAddress = response.data
      this.getAddress()
    })
  }
}

//获取收货地址
export function getAddress() {
  let { contactMan, contactTel, storeAddress, addressDetail } =
    this.form.storeAddress
  let process = ''
  if (storeAddress) {
    //省市区拼接
    storeAddress.forEach(i => {
      process += i
    })
  }
  let Address =
    (contactMan === null || contactMan === undefined ? '' : contactMan + ', ') +
    (contactTel === null || contactTel === undefined ? '' : contactTel + ', ') +
    (process === '' ? '' : process) +
    (addressDetail === null || addressDetail === undefined ? '' : addressDetail)
  this.form.storeAddressJoin = Address //拼接收货地址字符串
}

//收货地址省市区改变时获取省市区id
export function getAddressId() {
  this.form.storeAddress.storeAddress =
    this.$refs['cascader'].getCheckedNodes()[0].pathLabels //获取选中文本名称
  this.form.storeAddress.regionId =
    this.$refs['cascader'].getCheckedNodes()[0].data.id
}

//修改/编辑收货地址对话框
export function saveAddress() {
  this.getAddress()
  this.openAddress = false
}

//新增时获取当前时间
export function getCurTime(billType) {
  let myDate = new Date()
  let myYear = myDate.getFullYear() //获取完整的年份(4位,1970-????)
  let myMonth = myDate.getMonth() + 1 //获取当前月份(0-11,0代表1月)
  let myToday = myDate.getDate() //获取当前日(1-31)
  let hour = myDate.getHours() // 时
  let minutes = myDate.getMinutes() // 分
  let seconds = myDate.getSeconds() //秒
  myMonth = myMonth > 9 ? myMonth : '0' + myMonth
  myToday = myToday > 9 ? myToday : '0' + myToday
  if (hour >= 0 && hour <= 9) {
    hour = '0' + hour
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds
  }
  this.form.billDate = `${myYear}-${myMonth}-${myToday}`
  if (billType === '107') {
    const checkName = `${myYear}-${myMonth}-${myToday} ${hour}:${minutes}:${seconds} ${this.$store.getters.curTenant} 盘点单`
    this.$set(this.form, 'checkName', checkName)
  }
}

//计算采购/销售明细表格单价,含税价,税率，税额 根据两个值的变化自动计算
export async function handleEdit(row, sign, index) {
  console.log('进来了')
  //税额
  let unitTaxMoney
  let unitTaxPrice
  // 计算逻辑
  if (sign === 'unitQty') {
    this.calUnitPriceTaxMoney(row)
  }

  if (sign === 'unitTaxPrice') {
    //当修改含税单价时
    let unitPrice
    if (row.unitTaxPrice === undefined || Number(row.unitTaxPrice) === 0) {
      unitPrice = 0 //当含税单价等于0时  单价等于0
      unitTaxMoney = 0
    }
    if (row.taxRate === undefined || Number(row.taxRate) === 0) {
      unitPrice = row.unitTaxPrice //当税率等于0时, 单价等于含税单价
      unitTaxMoney = 0
    }
    if (row.unitTaxPrice && row.taxRate) {
      // 不含税单价 = 含税单价/(1+税率)
      unitPrice = row.unitTaxPrice / (1 + row.taxRate / 100)
    }
    //保存不含税单价并保留3位小数
    await this.showFormatting(
      3,
      'create',
      unitPrice,
      this.showForm.details,
      this.form.details,
      'unitPrice',
      index,
      'unitPrice'
    )
    this.calUnitPriceTaxMoney(row)
  }
  if (sign === 'unitPrice') {
    //当修改单价时
    let unitTaxPrice
    if (row.unitPrice === undefined || Number(row.unitPrice) === 0) {
      unitTaxPrice = 0 //当单价等于0时,含税单价等于0
      unitTaxMoney = 0
    }
    if (row.taxRate === undefined || Number(row.taxRate) === 0) {
      unitTaxPrice = row.unitPrice //当税率等于0时 ,含税单价等于不含税单价
      unitTaxMoney = 0
    }
    if (row.unitPrice && row.taxRate) {
      unitTaxPrice = row.unitPrice * (1 + row.taxRate / 100) // 含税单价 = 不含税单价*(1+税率)
    }
    //保存含税单价并保留3位小数
    await this.showFormatting(
      3,
      'create',
      unitTaxPrice,
      this.showForm.details,
      this.form.details,
      'unitTaxPrice',
      index,
      'unitTaxPrice'
    )
    this.callUnitTaxMoney(row)
  }

  if (sign === 'taxRate') {
    //当修改税率时
    let unitPrice
    // 税率 = ((含税金额-不含税金额) / 不含税金额) *100
    if (row.unitPrice && row.unitTaxPrice && row.taxRate) {
      //不含税单价/含税单价/税率都存在
      unitTaxPrice = row.unitPrice * (1 + row.taxRate / 100)
    }
    if (!row.unitPrice && row.unitTaxPrice && row.taxRate) {
      //含税单价不存在时
      unitPrice = row.unitTaxPrice / (1 + row.taxRate / 100)
    }
    if (row.taxRate === null || Number(row.taxRate) === 0) {
      //当税率等于0时
      if (row.unitTaxPrice && row.unitPrice) {
        unitPrice = row.unitTaxPrice
      } else if (row.unitTaxPrice && !row.unitPrice) {
        unitPrice = row.unitTaxPrice
      } else if (row.unitPrice && !row.unitTaxPrice) {
        unitTaxPrice = row.unitPrice
      }
      unitTaxMoney = 0
    }
    //保存含税单价/不含税单价并保留3位小数
    if (unitTaxPrice) {
      await this.showFormatting(
        3,
        'create',
        unitTaxPrice,
        this.showForm.details,
        this.form.details,
        'unitTaxPrice',
        index,
        'unitTaxPrice'
      )
    }
    if (unitPrice) {
      await this.showFormatting(
        3,
        'create',
        unitPrice,
        this.showForm.details,
        this.form.details,
        'unitPrice',
        index,
        'unitPrice'
      )
      this.calUnitPriceTaxMoney(row)
    }
  }
  if (row.unitPrice && row.taxRate) {
    //税额 = 不含税单价 * (税率 / 100)
    unitTaxMoney = row.unitPrice * (row.taxRate / 100)
  }
  if (row.unitPrice && !row.taxRate) {
    unitTaxMoney = 0
  }
  if (!row.unitPrice && row.unitPrice) {
    unitTaxPrice = 0
  }
  //保存税额并保留3位小数
  await this.showFormatting(
    3,
    'create',
    unitTaxMoney,
    this.showForm.details,
    this.form.details,
    'unitTaxMoney',
    index,
    'unitTaxMoney'
  )
  //价税合计 = (不含税单价 * 数量) + 税额
  let unitMoney
  if (row.unitPrice && row.unitQty && !row.unitTaxMoney) {
    unitMoney = row.unitPrice * row.unitQty
  }
  if (row.unitTaxMoney && row.unitQty && row.unitPrice) {
    unitMoney = row.unitPrice * row.unitQty + row.unitTaxMoney
  }
  console.log(row)
  console.log(unitMoney)
  //保存价税合计并保留3位小数
  if (unitMoney) {
    await this.showFormatting(
      3,
      'create',
      unitMoney,
      this.showForm.details,
      this.form.details,
      'unitMoney',
      index,
      'unitMoney'
    )
  }
}

//根据单据id 获取单据详情
export function getBillDetail(bool) {
  this.loading = true
  getDetailBill(this.billId, this.urlDetail).then(async response => {
    let temp = 1
    this.form = response.data
    console.log('csp - 采购明细 = ', this.form)
    if (bool) {
      //  需要收货地址跟字段转换保留n位小数才进入
      if (this.form.storeAddress === null) {
        this.form.storeAddress = {
          contactMan: undefined,
          contactTel: undefined,
          regionId: undefined,
          addressDetail: undefined
        }
      } else {
        this.getAddress()
      }
      let dataCopy = _.cloneDeep(response.data.details)
      for (let i = 0; i < dataCopy.length; i++) {
        let obj = { ...dataCopy[i] }
        this.$set(this.showForm.details, i, [])
        for (let key in obj) {
          if (
            key === 'unitPrice' ||
            key === 'unitTaxPrice' ||
            key === 'unitTaxMoney' ||
            key === 'attachMoney' ||
            key === 'taxRate'
          ) {
            await this.showFormatting(
              3,
              'create',
              obj[key],
              this.showForm.details,
              dataCopy,
              key,
              i
            )
          }
        }
      }
    }
    if (this.form.fileItems) {
      this.form.fileItems.forEach(i => {
        this.fileList.push({
          name: i.fileName,
          url: i.accessUrl,
          domain: i.domain,
          filePath: i.filePath,
          fileSize: i.fileSize,
          uid: new Date().getTime() + temp++
        })
      })
    }
    if (this.form.billStatus === '2') {
      this.disabled = true
      this.auditOrder = true
    } else if (this.form.billStatus === '4') {
      this.disabled = true
      this.stopOrder = true
      this.showAudit = true
    } else if (this.form.billStatus === '3') {
      this.disabled = true
      this.showAudit = true
      this.deleteOrder = true
    } else {
      this.disabled = false
      this.stopOrder = false
      this.showAudit = false
      this.auditOrder = false
      this.deleteOrder = false
      this.noAuditOrder = true
    }
    this.loading = false
  })
}

//订单明细表格合计  (采购业务/销售业务)
export function getSummaries(param) {
  const { columns, data } = param
  const sums = []
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = '合计'
      return
    }
    if (
      column.property === 'unitQty' || //数量
      column.property === 'unitTaxMoney' || //税额
      column.property === 'unitMoney' || //价税合计
      column.property === 'unitPriceTaxMoney' || //价税合计
      column.property === 'attachMoney'
    ) {
      //附加费
      const values = data.filter(
        item => !(item[`${column.property}`] === undefined)
      )
      const values2 = values.map(i => Number(i[`${column.property}`]))
      sums[index] = values2
        .reduce((prev, curr) => {
          return prev + curr
        }, 0)
        .toFixed(this.decimalNum)
    }
  })
  return sums
}

//订单明细表格合计  (仓库业务)
export function getStoreSummaries(param) {
  const { columns, data } = param
  const sums = []
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = '合计'
      return
    }
    if (
      column.property === 'basUnitAdjustQty' || //盘盈数
      column.property === 'basUnitAdjustMoney' || //盘盈金额
      column.property === 'basUnitLoseQty' || //盘亏数
      column.property === 'basUnitLoseMoney' || //盘亏金额
      column.property === 'finalQty' || //账面数
      column.property === 'unitPrice' || //单价
      column.property === 'unitQty' || //数量
      column.property === 'unitMoney'
    ) {
      const values = data.filter(
        item => !(item[`${column.property}`] === undefined)
      )
      const values2 = values.map(i => Number(i[`${column.property}`]))
      sums[index] = values2
        .reduce((prev, curr) => {
          return prev + curr
        }, 0)
        .toFixed(this.decimalNum)
    }
  })
  return sums
}

//选中的商品/源单 数据
export async function getSelectIds(data, bool) {
  if (!bool && this.TypeBill === '107') {
    //需要检验是否选了仓库时进入
    if (!this.form.storeId) {
      this.$message({
        message: '请先选择仓库',
        type: 'warning'
      })
      return
    }
  }
  this.form.details = this.form.details.filter(
    //过滤掉没有商品id的行
    item =>
      (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
      !(item.goodsId === undefined)
  )
  if (this.TypeBill === '107') {
    //仓库盘点进入  获取商品库存数
    const goodsIds = data.map(v => v.goodsId) //获取id数组
    const obj = {
      goodsIds,
      storeId: this.form.storeId
    }
    const res = await getGoodsFinalQty(obj) //获取库存数
    data = data.map(i => {
      res.data.goodsQtyList.forEach(j => {
        //遍历给每一项加上库存数
        if (i.goodsId === j.goodsId) {
          i.finalQty = j.finalQty //库存数赋值
          i.unitPrice = j.salePrice //单价字段转换赋值
          i.basUnitQty = 0 //初始化基本单位数量
        }
      })
      i.unitPrice = i.purPrice //商品价格字段转换
      return i
    })
  }
  this.form.details.push(...data) //追加到数组后面
  //字段转换并保留n位小数才进入
  for (let i = 0; i < this.form.details.length; i++) {
    this.$set(this.showForm.details, i, {})
    for (let key in this.form.details[i]) {
      let price = this.unitPriceType // 区别单价取进价/售价 unitPriceType = purPrice 取进价  unitPriceType = salePrice 取售价
      if (key === price) {
        this.showFormatting(
          3,
          'create',
          this.form.details[i][key],
          this.showForm.details,
          this.form.details,
          key,
          i,
          'unitPrice'
        )
        if (bool) this.handleEdit(this.form.details[i], 'unitPrice', i) //计算单价/含税单价/税率/税额进入
      }
      if (key === 'taxRate') {
        this.showFormatting(
          3,
          'create',
          this.form.details[i][key],
          this.showForm.details,
          this.form.details,
          key,
          i,
          key
        )
        if (bool) this.handleEdit(this.form.details[i], 'taxRate', i) //计算单价/含税单价/税率/税额进入
      }
    }
  }
}

//获取选中的供应商
export function getSupplier(row, form) {
  //row 选中的数据  form表单名称
  row.supplierName = row.partnerName
  row.supplierId = row.partnerId
  row.supplierNo = row.partnerNo
  this.$refs.selectFrame.getRow(row)
  this[form].partnerId = row.supplierId
}

//获取选中的仓库
export function getStore(row, form, store, ref, parent) {
  console.log('获取仓库 == ', ref)
  //row 选中的数据  form表单名称  store仓库名称  parent获取父组件的自定义下拉框
  if (parent) {
    this.$parent.$refs[ref].getRow(row)
  } else {
    this.$refs[ref].getRow(row)
  }
  if (this.storePostType) {
    //storePostType变量  仓库类型(storeId inStoreId outStoreId)
    this[form][this.storePostType] = row.storeId
  } else {
    //当storePostType 为空时  取传进来的store名称
    this[form][store] = row.storeId
  }
}

//获取自定义搜索框选中的id
export function getDiyselectId(id, type, form) {
  //type是属性名  form是表单名
  this[form][type] = id
  //当type是仓库时   当form === form  是明细表时才需要获取收货地址  diySelectType为true是采购或销售 false是仓库(仓库不需要收货地址)
  if (
    (type === 'storeId' || type === 'inStoreId' || type === 'outStoreId') &&
    form === 'form' &&
    this.diySelectType
  ) {
    this.setAddressInfo(id)
  } //根据仓库获取收货地址
}

//表格单元格回车事件   name属性名 这个属性的event index当前数组下标
export function keyup(name, event, index, bool) {
  if (!bool && this.TypeBill === '107') {
    //需要检验是否选了仓库时进入
    if (!this.form.storeId) {
      this.$message({
        message: '请先选择仓库',
        type: 'warning'
      })
      return
    }
  }
  //判断是否输入编码或条码
  if (this.form.details[`${index}`][`${name}`]) {
    listGoods({
      [name]: this.form.details[`${index}`][`${name}`],
      partnerId: this.form.partnerId,
      isEnableSale: this.isEnableSale
    }).then(async response => {
      // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
      if (response.total === 1) {
        event.target.blur()
        //使用vue的$set方法更新数组数据,使视图可编辑
        response.rows[0].unitPrice = response.rows[0].salePrice
        this.$set(this.form.details, index, response.rows[0])
        this.iskey = !this.iskey
        this.form.details = this.form.details.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.goodsId === undefined)) ||
            !(item.goodsId === undefined)
        )
        if (!bool && this.TypeBill === '107') {
          //需要获取商品当前库存数进入
          getGoodsFinalQty({
            goodsIds: [response.rows[0].goodsId],
            storeId: this.form.storeId
          }).then(response => {
            //账面数
            this.$set(
              this.form.details[index],
              'finalQty',
              response.data.goodsQtyList[0].finalQty
            )
            //初始化基本单位数量
            this.$set(this.form.details[index], 'basUnitQty', 0)
          })
        }

        //字段转换并保留n位小数
        for (let i = 0; i < this.form.details.length; i++) {
          for (let key in this.form.details[i]) {
            let price = this.unitPriceType // 区别单价取进价/售价 unitPriceType = purPrice 取进价  unitPriceType = salePrice 取售价
            if (key === price) {
              this.showFormatting(
                3,
                'create',
                this.form.details[i][key],
                this.showForm.details,
                this.form.details,
                key,
                i,
                'unitPrice'
              )
              if (bool) this.handleEdit(this.form.details[i], 'unitPrice', i) //计算单价/含税单价/税率/税额进入
            }
            if (key === 'taxRate') {
              this.showFormatting(
                3,
                'create',
                this.form.details[i][key],
                this.showForm.details,
                this.form.details,
                key,
                i,
                key
              )
              if (bool) this.handleEdit(this.form.details[i], 'taxRate', i) //计算单价/含税单价/税率/税额进入
            }
          }
        }
      } else {
        this.queryCode = this.form.details[`${index}`][`${name}`]
        this.OpenGoods = true
      }
    })
  } else {
    //没输入直接弹出商品信息框
    event.target.blur()
    this.OpenGoods = true
  }
}

//单价根据单位改变
export function changeUnitPrice(unitId, units, index, bool) {
  units.forEach(v => {
    if (v.unitId === unitId) {
      //仓库盘点时 需要自动基计算基本单位数量
      if (this.form.details[index].finalQty) {
        this.form.details[index].basUnitQty =
          v.basUnitQty * this.form.details[index].finalQty
      }
      if (this.unitPriceType === 'purPrice') {
        //区分单价取进价/售价   this.unitPriceType === 'purPrice' 取进价  this.unitPriceType === 'salePrice' 取售价
        //是采购业务 使用进价
        this.form.details[index].unitPrice = v.unitPrice
        //保留3位小数显示
        this.showFormatting(
          3,
          'create',
          v.unitPrice,
          this.showForm.details,
          this.form.details,
          'unitPrice',
          index,
          'unitPrice'
        )
        if (bool) this.handleEdit(this.form.details[index], 'unitPrice', index) //采购业务/销售业务 单价变化重新计算含税单价/税额/税率/价税合计
        if (!bool) this.onkeyupUnitQty(this.form.details[index]) //仓库业务  单价变化重新计算单价/金额
      } else {
        this.form.details[index].unitPrice = v.salePrice
        //保留3位小数显示
        this.showFormatting(
          3,
          'create',
          v.salePrice,
          this.showForm.details,
          this.form.details,
          'unitPrice',
          index,
          'unitPrice'
        )
        if (bool) this.handleEdit(this.form.details[index], 'unitPrice', index) //采购业务/销售业务 单价变化重新计算含税单价/税额/税率/价税合计
        if (!bool) this.onkeyupUnitQty(this.form.details[index]) //仓库业务  单价变化重新计算单价/金额
      }
    }
  })
}

//选择商品方法
export function openSelectGoods(bool) {
  if (!bool && this.TypeBill === '107') {
    //需要检验是否选了仓库时进入
    if (!this.form.storeId) {
      this.$message({
        message: '请先选择仓库',
        type: 'warning'
      })
      return
    }
  }
  this.OpenGoods = true
  this.selectGoods = '选择商品'
}

//引入源单按钮方法
export function selectBills(query = {}) {
  // 需要先判断是否选择供应商
  if (this.BillSaleType) {
    if (!this.form.partnerName) {
      this.$message.error('请先选择供应商')
      return
    }
  } else {
    if (!this.form.partnerName) {
      this.$message.error('请先选择客户')
      return
    }
  }
  this.selectType = '引入源单'
  let title = ''
  if (this.TypeBill === '110101' || this.TypeBill === '110102') {
    title = '采购订单'
  } else if (this.TypeBill === '110103') {
    title = '采购入库单'
  } else if (this.TypeBill === '105') {
    title = '销售订单'
  } else if (this.TypeBill === '120103') {
    title = '销售退货单'
  } else if (this.TypeBill === '140303') {
    title = '门店采购'
  } else if (this.TypeBill === '120102') {
    title = '销售订单'
  }
  this.title = title
  this.NoFields = [
    { label: '单据编号', name: 'billNo' },
    { label: '单据状态', name: 'billStatus' }
  ]
  this.tableColumnFields = [
    { label: '单据时间', name: 'billDate' },
    { label: '仓库名称', name: 'storeName' },
    { label: '部门', name: 'purDeptName' },
    {
      label: `${this.TypeBill != '140303' &&
          this.TypeBill != '110102' &&
          this.TypeBill != '110103'
          ? '业务员'
          : '采购员'
        }`,
      name: this.TypeBill == '120103' ? 'saleEmployeeName' : 'purEmployeeName'
    },
    { label: '手工单据号', name: 'handBillNo' },
    { label: '商品编码', name: 'goodsNo' },
    { label: '商品名称', name: 'goodsName' },
    { label: '单位', name: 'unitName' },
    { label: '数量', name: 'unitQty' },
    { label: '单价', name: 'unitPrice' },
    { label: '含税单价', name: 'unitTaxPrice' },
    { label: '税额', name: 'unitTaxMoney' },
    { label: '价税合计', name: 'unitPriceTaxMoney' },
    { label: '备注', name: 'remark' }
  ]
  this.loadingDia = true
  if (this.TypeBill === '140303') {
    //门店采购时
    query.billType = 110101
  } else {
    query.billType = Number(this.TypeBill) - 1
  }
  // 添加供应商id
  query.partnerId = this.form.partnerId
  getSourceBill(this.urlSourceBill, query).then(response => {
    // 源单所有数据
    this.billList = [...response.rows]
    // 全选时需要的数据
    this.allBillList = [...response.rows]
    this.billTotal = response.total
    this.loadingDia = false
    this.openBillDia = true
  })
  // this.getSelectBills(query) //获取所有数据  (暂时)
}

//获取引入源单全部数据
export async function getSelectBills(query) {
  console.log('这里了吗?')
  let pageSize = this.billTotal
  if (this.TypeBill === '140303') {
    //门店采购时
    query.billType = 110101
  } else {
    query.billType = Number(this.TypeBill) - 1
  }
  const response = await getSourceBill(this.urlSourceBill, {
    ...query,
    pageSize: pageSize
  })
  this.allBillList = [...response.rows]
  this.billTotal = response.total
}

//表单重置
export function reset() {
  //显示的表单
  this.showForm = {
    //单据表格-订单明细
    details: [{}]
  }
  //表单数据
  this.form = {
    //单据id
    deptId: undefined,
    //单据id
    billId: undefined,
    //制单日期
    billDate: undefined,
    //单据编号
    billNo: undefined,
    //供应商
    partnerId: undefined,
    //订单仓库
    storeId: undefined,
    //入库仓库
    inStoreId: undefined,
    //出库仓库
    outStoreId: undefined,
    //采购员
    purEmployeeId: undefined,
    //单据状态
    billStatus: undefined,
    //制单人
    createEmployeeId: undefined,
    //单据金额
    billMoney: undefined,
    //单据不含税金额
    billNotaxMoney: undefined,
    //单据含税金额
    billTaxMoney: undefined,
    //备注
    remark: undefined,
    //单据表格-订单明细
    details: [{}],
    //附件数据
    fileItems: [],
    //收货地址
    storeAddress: {},
    //收货地址拼接
    storeAddressJoin: undefined
  }
  this.fileList = []
  this.resetForm('form')

  this.sourceStatus = false
}

//初始化收货地址表单
export function resetAddress() {
  const storeAddress = {
    contactMan: undefined,
    contactTel: undefined,
    regionId: undefined,
    addressDetail: undefined
  }
  return storeAddress
}

//表格增加/减少一行方法
export function row(name, index) {
  if (name === 'push') {
    this.form.details.splice(index + 1, 0, {})
    this.showForm.details.splice(index + 1, 0, {})
  } else {
    if (this.form.details.length <= 1) {
      this.$set(this.form.details, index, [])
      this.$set(this.showForm.details, index, [])
      this.iskey = !this.iskey
      return
    }
    this.form.details.splice(index, 1)
    this.showForm.details.splice(index, 1)
    this.iskey = !this.iskey
  }
}

//保存并新增单据
export async function AddBill() {
  if (this.form.billStatus === '2') {
    this.reset()
    this.disabled = false
    this.auditOrder = false
    return
  } else if (this.form.billStatus === '4') {
    this.reset()
    this.disabled = false
    this.stopOrder = false
    return
  } else if (this.form.billStatus === '3') {
    this.reset()
    this.disabled = false
    this.deleteOrder = false
    return
  }
  try {
    await this.$confirm('单据数据已修改,是否保存?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'success'
    })
    this.submitForm(true)
  } catch { }
}

///更新单据状态 (审核,反审核,关闭)
export async function changeBillStatus(name, bool) {
  try {
    await this.$confirm(`确定${name}该单据, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'success'
    })
    if (name === '审核') {
      updateInstoreStatus(
        [this.form.billId],
        '2',
        this.urlBillStatus,
        this.TypeBill
      ).then(response => {
        this.form = response.data
        if (bool) {
          //有收货地址才进入  初始化收货地址
          if (
            response.data.storeAddress === null ||
            response.data.storeAddress === undefined
          ) {
            response.data.storeAddress = this.resetAddress()
          }
          this.getAddress()
        }
        console.log('审核1 == ', this.auditOrder)

        this.disabled = true //是否禁用
        this.auditOrder = true //审核图标
        this.noAuditOrder = false //未审核图标
        console.log('审核2 == ', this.auditOrder)
      })
    } else if (name === '反审核') {
      updateInstoreStatus(
        [this.form.billId],
        '0',
        this.urlBillStatus,
        this.TypeBill
      ).then(response => {
        this.form = response.data
        if (bool) {
          //有收货地址才进入   初始化收货地址
          if (
            response.data.storeAddress === null ||
            response.data.storeAddress === undefined
          ) {
            response.data.storeAddress = this.resetAddress()
          }
          this.getAddress()
        }
        this.disabled = false
        this.auditOrder = false
        this.noAuditOrder = true
      })
    } else if (name === '关闭') {
      updateInstoreStatus(
        [this.form.billId],
        '4',
        this.urlBillStatus,
        this.TypeBill
      ).then(response => {
        this.form = response.data
        if (bool) {
          //有收货地址才进入   初始化收货地址
          if (
            response.data.storeAddress === null ||
            response.data.storeAddress === undefined
          ) {
            response.data.storeAddress = this.resetAddress()
          }
          this.getAddress()
        }
        this.disabled = true
        this.showAudit = true
        this.stopOrder = true
      })
    }
    // 弹出提示
    this.$message.success(`${name}单据成功`)
  } catch { }
}

/** 提交按钮 */
export function submitForm(bool, type) {
  console.log('提交按钮：', bool, type)
  if (this.form.details.length > 1) {
    //过滤明细表中输入空的一行
    this.form.details = this.form.details.filter(
      item =>
        (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
        !(item.goodsId === undefined)
    )
  }
  if (
    !(JSON.stringify(this.form.details[0]) === '{}') && //判断明细里第一项是否为空
    !(this.form.details[0].goodsId === undefined)
  ) {
    this.$refs['form'].validate(valid => {
      //表单校验
      if (valid) {
        if (this.form.billId != undefined) {
          //有id为修改
          updateBill(this.form, this.urlUpdate).then(response => {
            this.form = response.data //给form赋值
            if (!(type === '仓库')) {
              //有收货地址才需要初始化收货地址 并拼接收货地址
              if (
                this.form.storeAddress === null ||
                this.form.storeAddress === undefined
              ) {
                //收货地址为空  初始化收货地址
                this.form.storeAddress = this.resetAddress()
              }
              this.getAddress() //拼接收货地址字符串
            }
            this.$modal.msgSuccess('修改成功') //弹出提示
            //判断当bool是true 是保存并新增指令 要清空form
            if (bool) {
              this.noAuditOrder = false
              this.reset()
              this.getCurTime()
              this.getNewBillNo(this.form, 'billNo', Number(this.TypeBill))
            }
          })
        } else {
          addBill(this.form, this.urlAdd).then(response => {
            this.form = response.data
            console.log('form p', this.form)
            if (!(type === '仓库')) {
              if (
                this.form.storeAddress === null ||
                this.form.storeAddress === undefined
              ) {
                //收货地址为空  初始化收货地址
                this.form.storeAddress = this.resetAddress()
              }
              this.getAddress() //拼接收货地址字符串
            }
            this.$modal.msgSuccess('新增成功')
            this.noAuditOrder = true
            //判断bool是true 是新增指令 要清空form
            if (bool) {
              this.noAuditOrder = false
              this.reset()
              this.getCurTime()
              this.getNewBillNo(this.form, 'billNo', Number(this.TypeBill))
            }
          })
        }
      }
    })
  } else {
    this.$message.error('单据明细不能为空')
  }
}

//仓库明细表

//数量键盘抬起  计算金额/数量
export function onkeyupUnitQty(row) {
  //计算金额  数量*单价
  const unitMoney = Number(row.unitQty) * Number(row.unitPrice)
  this.$set(row, 'unitMoney', unitMoney)
  //仓库盘点计算
  if (this.TypeBill === '107') {
    //计算基本单位数量   盘点数 * 基本单位比例系数
    row.units.forEach(v => {
      if (row.unitId === v.unitId) {
        this.$set(row, 'basUnitQty', row.unitQty * v.basUnitQty)
      }
    })
    //仓库盘点计算盘盈/盘亏
    const num1 = row.basUnitQty - row.finalQty
    if (num1 > 0) {
      //使用$set方法使数据变化表格视图可见
      //盘盈数量
      this.$set(row, 'basUnitAdjustQty', num1)
      //盘盈金额
      this.$set(
        row,
        'basUnitAdjustMoney',
        row.basUnitAdjustQty * Number(row.unitPrice)
      )
      //盘亏数量
      this.$set(row, 'basUnitLoseQty', 0)
      //盘亏金额
      this.$set(row, 'basUnitLoseMoney', 0)
    } else if (num1 === 0) {
      //盘盈数量
      this.$set(row, 'basUnitAdjustQty', 0)
      //盘盈金额
      this.$set(row, 'basUnitAdjustMoney', 0)
      //盘亏数量
      this.$set(row, 'basUnitLoseQty', 0)
      //盘亏金额
      this.$set(row, 'basUnitLoseMoney', 0)
    } else if (num1 < 0) {
      const num2 = row.finalQty - row.basUnitQty
      //盘亏数量
      this.$set(row, 'basUnitLoseQty', num2)
      //盘亏金额
      this.$set(row, 'basUnitLoseMoney', row.basUnitLoseQty * row.unitPrice)
      //盘盈数量
      this.$set(row, 'basUnitAdjustQty', 0)
      //盘盈金额
      this.$set(row, 'basUnitAdjustMoney', 0)
    }
  }
}
